<template>
  <div id="library3D">
    <mainMenu></mainMenu>
    <div class="headerPreview">
      <div class="wrapper">
        <div class="sectionInfo">
          <h1 class="sectionTitle">3D библиотеки</h1>
          <h2 class="sectionDescription">
            - Цифровая библиотека фасонов зубов для моделирования <br />
            реставрации и дизайна улыбки
            <br />
            - Анатомия черепа в 3D
            <br />
            - 3D файлы для изготовления ортодонтических систем
            <br />
            - Все стоматологические файлы
          </h2>
        </div>
      </div>
    </div>
    <div class="library3dView">
      <div class="wrapper">
        <div class="library3dViewItem">
          <div class="library3dViewCategories">
            <router-link :to="{ name: 'toothStylesExpression' }" class="library3dViewItem">
              <img
                src="../../assets/images/library3d/lib_category_tooth_style_expression.jpg"
                class="library3dViewItemLogo"
                alt=""
              />
              <div class="library3dViewItemContent">Цифровая библиотека фасонов зубов</div>
            </router-link>
            <router-link :to="{ name: 'orthodonticSystems' }" class="library3dViewItem">
              <img src="../../assets/images/library3d/lib_category_breket.jpg" class="library3dViewItemLogo" alt="" />
              <div class="library3dViewItemContent">3D файлы для работы для ортодонтии</div>
            </router-link>
            <router-link :to="{ name: 'toothStyles' }" class="library3dViewItem">
              <img
                src="../../assets/images/library3d/lib_category_tooth_style_pogarsky.jpg"
                class="library3dViewItemLogo"
                alt=""
              />
              <div class="library3dViewItemContent">Цифровая библиотека фасонов зубов</div>
            </router-link>
            <router-link :to="{ name: 'attachmentsLibrary' }" class="library3dViewItem">
              <img
                src="../../assets/images/library3d/lib_category_attachments.jpg"
                class="library3dViewItemLogo"
                alt=""
              />
              <div class="library3dViewItemContent">Аттачменты</div>
            </router-link>
            <router-link :to="{ name: 'skull3d' }" class="library3dViewItem">
              <img src="../../assets/images/library3d/lib_category_skull.jpg" class="library3dViewItemLogo" alt="" />
              <div class="library3dViewItemContent">Череп человека по частям в формате STL</div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <mainFooter></mainFooter>
  </div>
</template>

<script>
import mainMenu from "./../mainMenu/mainMenu.vue";
import mainFooter from "./../mainFooter/mainFooter.vue";

export default {
  name: "library3D",
  components: { mainMenu, mainFooter },
  data() {
    return {};
  },
};
</script>

<style lang="sass">
#library3D
	display: flex
	flex-direction: column
	min-height: calc(100vh - 155px)
	& .headerPreview
		display: flex
		min-height: 365px
		align-items: center
		padding: 0 2rem
		background: url('./../../assets/images/library3d/library3d_background.jpg')
		background-size: cover
		background-position: center center
		& .sectionInfo
			width: fit-content
			@media screen and (max-width: 426px)
				display: flex
				flex-direction: column
				align-items: center
				width: 100%
				text-align: center
			& .sectionTitle
				color: #37ffea
				font-size: 3rem
				padding-bottom: 1.5rem
				margin: 0 0 1.5rem
				text-shadow: 1px 1px 5px #000
				// border-bottom: 1px solid #f7941d
				@media screen and (max-width: 426px)
					font-size: 1.7rem
					padding-bottom: 0.5rem
					margin: 0 0 0.5rem
			& .btnAbout
				background: #f7941d
				color: #fff
				display: block
				padding: 0.5rem 2rem
				border: 1px solid transparent
				cursor: pointer
			& .sectionDescription
				font-family: 'Ropa Sans Pro SC Regular'
				font-weight: 400
				font-size: 1.3rem
				color: #fff
				margin-top: 1.5rem
				text-shadow: 1px 1px 5px #000
	.library3dView
		display: flex
		flex-direction: column
		.library3dViewCategories
			padding: 1rem 0rem
			column-count: 3
			column-gap: 2rem
			@media screen and (max-width: 769px)
				column-count: 2
				column-gap: 1rem
			@media screen and (max-width: 425px)
				column-count: 1
			& .library3dViewItem
				display: block
				position: relative
				cursor: pointer
				margin-bottom: 1rem
				& .library3dViewItemLogo
					display: block
					width: 100%
					height: 100%
					object-fit: cover
				& .library3dViewItemContent
					position: absolute
					left: 0
					right: 0
					bottom: 0
					font-weight: bold
					color: #333
					min-height: 80px
					background: rgba(255, 255, 255, 0.7)
					padding: 1rem 1.5rem
					font-size: 1rem
</style>
